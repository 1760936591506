import React from 'react';
import ArticleItem from './Articles/ArticleItem';
import { connect } from 'react-redux';
import { fetchTopArticlesWeekly } from '../actions';
class StatisticsArticles extends React.Component {
    componentDidMount() {
        this.props.fetchTopArticlesWeekly('zh');
    }

    renderWeeklyList() {
        if (this.props.top_10_articles_weekly.length > 0) {
            return this.props.top_10_articles_weekly.map((article) => <ArticleItem article={article} key={article.id} />);
        } else {
            return <div>抱歉，目前還沒有統計資料喔。</div>
        }
    }

    render() {
        return (
            <div className="ui segment">
                <h2 className="ui left floated header">Weekly Top 10 Articles</h2>
                <div className="ui clearing divider"></div>
                {this.renderWeeklyList()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { top_10_articles_weekly: state.statistics.top_10_articles_weekly };
};

export default connect(mapStateToProps, { fetchTopArticlesWeekly })(StatisticsArticles);
