import { SET_USER_PREFERENCE } from '../actions/types';
import { defaultLanguage, defaultCountry } from '../locales'

export const INITIAL_STATE = {
    country: defaultCountry,
    language: defaultLanguage,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_PREFERENCE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
