import zhTW from './zhTW'
import en from './en'
import ja from './ja'
import th from './th'
import pt from './pt'

export const defaultLanguage = 'zhTW'
export const defaultCountry = 'zhTW'

export const momentLocaleMapping = {
    zhTW: 'zh-TW',
    en: 'en',
    ja: 'ja',
    th: 'th',
    pt: 'pt',
}

export const acceptLanguage = {
    zhTW: 'zh',
    en: 'en',
    ja: 'jp',
    th: 'th',
    pt: 'pt',
}

const locales = {
    zhTW,
    en,
    ja,
    th,
    pt,
}

export default locales
