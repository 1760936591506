const pt = {
	article_list: 'Lista de artigos',
	statistics: 'Estatisticas',
	article_search: 'Pesquisa de artigos',
	more_articles: 'Mais artigos',
	sign_out: 'Sair',
	sign_in_with_google: 'Faça login no Google',
	back_to_article_list: 'Voltar à lista de artigos',
	loading: 'Carregando',
	replies: 'Respostas',
	queries: 'Queries',
	submit: 'Consultas',
	reply_source_label: 'Source',
	reply_content: 'Contente',
	reply_content_placeholder: {
		undefined: 'Insira o conteúdo de verificação',
		RUMOR:
			'Por favor, indique brevemente o motivo da mensagem de erro, o chatbot entenderá imediatamente por que o guia errado foi dado ao receber uma resposta',
		NOT_RUMOR:
			'Por favor, indique brevemente o motivo da mensagem correta, o chatbot entenderá imediatamente por que o guia correto é fornecido quando receber uma resposta',
		OPINIONATED:
			'Por favor, lembre ao leitor que parte desta mensagem é uma opinião pessoal e não um fato objetivo',
		NOT_ARTICLE: 'Explique por que você não precisa processar esta mensagem',
	},
	reply_source: {
		undefined: 'Informações de Apoio',
		RUMOR: 'Informações de Apoio',
		NOT_RUMOR: 'Informações de Apoio',
		OPINIONATED: 'Objeção e fonte de informação',
	},
	reply_templete_button_text: {
		content_too_short: 'O comprimento é muito curto',
		commercial: 'Promoção Comercial',
		no_valid_required: 'Nenhuma verificação necessária',
		chat: 'Bater papo',
		test: 'Teste',
		invalid_link: 'Link inválido',
	},
	reply_templete_content: {
		content_too_short:
			'O comprimento do texto é muito curto e suspeita-se que seja uma frase de consulta inserida manualmente pelo usuário, não como um artigo republicado.',
		commercial:
			'Este é um anúncio de evento comercial. Durante o período do evento...',
		no_valid_required:
			'A mensagem não tem nada a ver com a verificação do boato.',
		chat: 'A pessoa que enviou o artigo está tentando conversar com o bot.',
		test: 'Teste',
		invalid_link:
			'Este link não é mais válido e nenhum resultado correspondente pode ser encontrado após a pesquisa de pistas relevantes pelos mecanismos de pesquisa, que não podem ser efetivamente verificadas',
	},
	reply_source_placeholder:
		'Descrição da fonte e URL de destino, se houver várias fontes, use enter para quebrar a linha para os usuários lerem',
	empty_reply_content: 'Você deve dar uma razão para responder um artigo',
	empty_reply_type: 'Você deve selecionar um tipo para sua resposta',
	select_reply_type: 'Selecione o tipo de resposta',
	NOT_ARTICLE: 'NÃO ARTIGO',
	OPINIONATED: 'OPINIÃO',
	NOT_RUMOR: 'NÃO RUMOR',
	RUMOR: 'RUMOR',
	locale: {
		zhTW: '繁體中文',
		en: 'English',
		ja: '日本語',
		th: 'ไทย',
		pt: 'Portugal',
	},
	articleFilter: {
		not_replied: 'Não respondido',
		replied: 'Respondido',
		popular_inquiry: 'Consulta popular',
		politics: 'Político',
		health_information: 'Saúde',
		chicken_soup_for_the_soul: 'Sopa de galinha para a alma',
		scam: 'Fraude',
		topics: 'Tópicos',
		filters: 'Filtros',
	},
	replied_article_list: 'Lista de artigos respondidos',
	sorted_by: 'Classificado por',
	newest: 'O mais novo',
	most_asked: 'Mais Perguntados',
	article_topics: {
		politics: 'Político',
		health_information: 'Saúde',
		chicken_soup_for_the_soul: 'Sopa de galinha para a alma',
		scam: 'Fraude',
		add_topics: 'Adicionar tópico',
	},
	use_this_reply: 'Use esta resposta',
	no_related_articles: 'Nenhum artigo relacionado',
	direct_reply: 'Responda diretamente',
	related_article_replies: 'Responda a artigos relacionados',
}

export default pt
