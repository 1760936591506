
import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import { setArticleSort, setArticlePagination, fetchArticles } from '../../actions'

const Sorter = () => {
  const dispatch = useDispatch()
  const { field } = useSelector(state => state.article.filter.sort )

  const handleSelect = useCallback((event) => {
    dispatch(setArticleSort({ field: event.target.value, order: 'desc' }))
    dispatch(setArticlePagination({ page: 1 }))
    dispatch(fetchArticles())
  }, [dispatch])

  return (
    <div className='ui grid'>
      <div className='ui row'>
        <div className='column'>
          <div style={{ position: 'absolute', right: '1rem' }}>
            <label style={{ paddingRight: '1rem' }}>{I18n.t('sorted_by')}</label>
            <select className='ui dropdown' defaultValue={field} onChange={handleSelect}>
              <option value='created_at'>{I18n.t('newest')}</option>
              <option value='query_count'>{I18n.t('most_asked')}</option>
            </select>
          </div>
        </div>
      </div>
      <div className='ui hidden divider'></div>
    </div>
  )
}

export default Sorter
