const initialState = {
    top_10_articles_weekly: [],
    top_10_replies_weekly: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_TOP_10_ARTICLES_WEEKLY':
            return { ...state, top_10_articles_weekly: action.payload }
        case 'FETCH_TOP_10_REPLIES_WEEKLY':
            return { ...state, top_10_replies_weekly: action.payload }
        default:
            return state
    }
};
