import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Label, Dropdown } from 'semantic-ui-react'
import { I18n } from 'react-redux-i18n'

import { updateArticleTopics } from '../../actions'
import { HTTP_STATUS_CODE } from '../../apis/constant'

const topicsOption = ['politics', 'health_information', 'chicken_soup_for_the_soul', 'scam']

export const useSelectedTopics = ({
  articleId,
  defaultSelectedTopics,
  isSignedIn = false
}) => {
  const [selectedTopics, setSelectedTopics] = React.useState(defaultSelectedTopics || [])
  const [previousSelectedTopics, setPreviousSelectedTopics] = React.useState([])

  const putSelectedTopics = React.useCallback(async () => {
    const response = await updateArticleTopics(articleId, selectedTopics)
    if (response.status !== HTTP_STATUS_CODE.OK) setSelectedTopics(previousSelectedTopics)
  }, [articleId, selectedTopics, previousSelectedTopics])

  const addSelectedTopic = async selectedTopic => {
    if (!isSignedIn) return
    const currentSelectedTopics = [...selectedTopics]
    const newSelectedTopics = [...selectedTopics, selectedTopic]
    setPreviousSelectedTopics(currentSelectedTopics)
    setSelectedTopics(newSelectedTopics)
  }

  const removeSelectedTopic = async selectedTopic => {
    if (!isSignedIn) return
    const currentSelectedTopics = [...selectedTopics]
    setPreviousSelectedTopics(currentSelectedTopics)
    setSelectedTopics(selectedTopics.filter(topic => topic !== selectedTopic))
  }

  React.useEffect(() => {
    putSelectedTopics()
  }, [putSelectedTopics, selectedTopics])

  return {
    selectedTopics,
    addSelectedTopic,
    removeSelectedTopic,
  }
}

export const ArticleTopicsDropdown = props => React.useMemo(() => {
  const dropdownOptions = props.options
    .filter(topic => (!props.selectedOptions.includes(topic)))
    .map(topic => ({ key: topic, text: I18n.t(`article_topics.${topic}`), value: topic, onClick: () => props.onClick(topic) }))

  return (
    <Label as='a' tag>
      <Dropdown text={I18n.t('article_topics.add_topics')} options={dropdownOptions} />
    </Label>
  )
}, [props])

export const ArticleTopicsSelector = props => {
  const { selectedTopics, addSelectedTopic, removeSelectedTopic } = useSelectedTopics({
    articleId: props.articleId,
    defaultSelectedTopics: props.selectedTopics,
    isSignedIn: props.isSignedIn,
  })

  const displaySelectedTopics = selectedTopics.map((topic) =>
    <Label key={topic} as='a' onClick={() => removeSelectedTopic(topic)}>
      <i className="times icon"></i>
      {I18n.t(`article_topics.${topic}`)}
    </Label>
  )
  
  return (
    <Label.Group tag>
      {displaySelectedTopics}
      <ArticleTopicsDropdown options={topicsOption} selectedOptions={selectedTopics} onClick={addSelectedTopic} />
    </Label.Group>
  )
}

export default ArticleTopicsSelector

ArticleTopicsDropdown.propTypes = {
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  onClick: PropTypes.func,
}

ArticleTopicsSelector.propTypes = {
  articleId: PropTypes.string,
  selectedTopics: PropTypes.array,
  isSignedIn: PropTypes.bool,
}

ArticleTopicsDropdown.defaultProps = {
  options: [],
  selectedOptions: [],
  onClick: noop,
}

ArticleTopicsSelector.defaultProps = {
  articleId: '',
  selectedTopics: [],
  isSignedIn: false,
}
