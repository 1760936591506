import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n'

import { fetchArticles, fetchMoreArticles, beforeFetchArticles, nextArticlePage, resetArticleList } from '../../actions';
import ArticleItem from './ArticleItem';

class ArticleList extends React.Component {
    loadNextPage = () => {
        this.props.beforeFetchArticles();
        this.props.nextArticlePage();
        this.props.fetchMoreArticles();
    }

    backToList = () => {
        this.props.resetArticleList();
        this.props.fetchArticles();
    }

    renderList() {
        return this.props.articles.map((article) => <ArticleItem article={article} key={article.id} />);
    }

    renderLoadButton() {
        if (this.props.isLastPage) {
            return <div className="ui green inverted center aligned segment" onClick={this.backToList}>{I18n.t('back_to_article_list')}</div>;
        }

        if (this.props.isAritclesOnLoanding) {
            return <div className="ui green inverted center aligned segment">{I18n.t('loading')}...</div>;
        } else {
            return <div className="ui green inverted center aligned segment" onClick={this.loadNextPage}>{I18n.t('more_articles')}</div>;
        }
    }

    renderLoader() {
        return (
            <div className="ui active inverted dimmer">
                <div className="ui active loader"></div>
                <p></p>
            </div>
        );
    }

    render() {
        if (this.props.isAritclesOnLoanding) {
            return this.renderLoader();
        } else {
            return (
                <div className="ui relaxed divided list">
                    {this.renderList()}
                    {this.renderLoadButton()}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return { articles: state.article.list, isAritclesOnLoanding: state.article.onLoading, currentArticlePage: state.article.currentPage, isLastPage: state.article.isLastPage };
};

export default connect(mapStateToProps, { fetchArticles, fetchMoreArticles, beforeFetchArticles, nextArticlePage, resetArticleList })(ArticleList);
