const th = {
	article_list: 'บทความ',
	statistics: 'สถิติ',
	article_search: 'ค้นหาบทความ',
	more_articles: 'แสดงเพิ่มเติม',
	sign_out: 'ออกจากระบบ',
	sign_in_with_google: 'เข้าสู่ระบบด้วย Google',
	back_to_article_list: 'กลับไปสู่รายการของบทความ',
	loading: 'กำลังโหลดข้อมูล',
	replies: 'คำตอบ',
	queries: 'ถูกค้นหา',
	submit: 'ส่งคำตอบ',
	reply_source_label: 'แหล่งที่มา',
	reply_content: 'รายละเอียดของคำตอบ',
	reply_content_placeholder: {
		undefined: 'พิมพ์รายละเอียดของคำตอบที่นี้',
		RUMOR: 'กรุณาพิมพ์รายละเอียดแบบคร่าวๆ เพื่อให้แชทบอตรู้จักกับคำตอบนี้',
		NOT_RUMOR: 'กรุณาพิมพ์รายละเอียดแบบคร่าวๆ เพื่อให้แชทบอตรู้จักกับคำตอบนี้',
		OPINIONATED: 'คำตอบของท่านจะแสดงให้ผู้อื่นเห็นในรูปของความคิดเห็นส่วนตัว',
		NOT_ARTICLE: 'ช่วยบอกเราหน่อยว่าทำไมสิ่งนี้ไม่ใช่บทความ',
	},
	reply_source: {
		undefined: 'แหล่งที่มา',
		RUMOR: 'แหล่งที่มา',
		NOT_RUMOR: 'แหล่งที่มา',
		OPINIONATED: 'เหตุผลในการรายงานครั้งนี้และแหล่งที่มา',
	},
	reply_templete_button_text: {
		content_too_short: 'รายละเอียดสั้นเกินไป',
		commercial: 'โฆษณา',
		no_valid_required: 'ไม่ต้องตรวจสอบ',
		chat: 'แชท',
		test: 'ทดสอบ',
		invalid_link: 'ลิงก์ผิด',
	},
	reply_templete_content: {
		content_too_short: 'รายละเอียดสั้นเกินไป',
		commercial: 'บทความนี้เป็นเพียงโฆษณา',
		no_valid_required: 'บทความนี้ไม่จำเป็นต้องตรวจสอบ',
		chat: 'เป็นเพียงข้อความจากแชท',
		test: 'ทดสอบ',
		invalid_link: 'ลิงค์นี้ไม่ถูกค้นหามานานแล้ว',
	},
	reply_source_placeholder: 'แหล่งที่มาของคำตอบ',
	empty_reply_content: 'กรุณากรอกรายละเอียด',
	empty_reply_type: 'กรุณาเลือกประเภทของคำตอบ',
	select_reply_type: 'เลือกประเภทของคำตอบ',
	NOT_ARTICLE: 'ไม่ใช่บทความ',
	OPINIONATED: 'ความคิดเห็น',
	NOT_RUMOR: 'ไม่ใช่ข่าวลือ',
	RUMOR: 'ข่าวลือ',
	locale: {
		zhTW: '繁體中文',
		en: 'English',
		ja: '日本語',
		th: 'ไทย',
		pt: 'Portugal',
	},
	articleFilter: {
		not_replied: 'ยังไม่มีคำตอบ',
		replied: 'มีคำตอบแล้ว',
		popular_inquiry: 'คำถามยอดนิยม',
		politics: 'การเมือง',
		health_information: 'สุขภาพ',
		chicken_soup_for_the_soul: 'แรงบันดาลใจ',
		scam: 'สแปม',
		topics: 'หัวข้อ',
		filters: 'ตัวกรอง',
	},
	replied_article_list: 'บทความที่มีคำตอบแล้ว',
	sorted_by: 'เรียงตาม',
	newest: 'ใหม่ล่าสุด',
	most_asked: 'ยอดฮิต',
	article_topics: {
		politics: 'การเมือง',
		health_information: 'สุขภาพ',
		chicken_soup_for_the_soul: 'แรงบันดาลใจ',
		scam: 'สแปม',
		add_topics: 'เพิ่มแท็ก',
	},
	direct_reply: 'รายงานบทความนี้',
	use_this_reply: 'ใช้คำตอบนี้',
	related_article_replies: 'รายงานบทความนี้ด้วยคำตอบที่เคยมีอยู่แล้ว',
}

export default th
