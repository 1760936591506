import ArticlesPage from './components/Articles';
import RepliedArticlePage from './components/Articles/RepliedArticlePage';
import StatisticsPage from './components/StatisticsPage';

const routes = [
  {
    name: 'ArticlesPage',
    component: ArticlesPage,
    path: '/',
    exact: true,
  },
  {
    name: 'RepliedArticlePage',
    component: RepliedArticlePage,
    path: '/replied',
    exact: true,
  },
  {
    name: 'StatisticsPage',
    component: StatisticsPage,
    path: '/statistics',
    exact: true,
  },
]

export default routes
