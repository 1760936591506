import React from 'react'
import { connect } from 'react-redux'
import { ReactTinyLink } from 'react-tiny-link'
import normalizeUrl from 'normalize-url'

class ReplyItem extends React.Component {
	renderHyperlinks(hyperlinks) {
		return hyperlinks.map((hyperlink) => (
			<ReactTinyLink
				key={hyperlink}
				cardSize='small'
				showGraphic={true}
				maxLine={2}
				minLine={1}
				url={normalizeUrl(hyperlink)}
			/>
		))
	}

	render() {
		const { reply, replyTypeEnums } = this.props
		const replyType = replyTypeEnums[reply.type.toLowerCase()] || reply.type

		return (
			<div className='ui segment'>
				<div className='content'>
					<p className='header'>{replyType}</p>
					<p className='description'>{reply.content}</p>
				</div>
				<div>{this.renderHyperlinks(reply.hyperlinks)}</div>
			</div>
		)
	}
}

const mapStateToProps = (state, _ownProps) => {
	return {
		replyTypeEnums: state.enums.replyType,
	}
}

export default connect(mapStateToProps)(ReplyItem)
