import _ from 'lodash';
import { setLocale } from 'react-redux-i18n';

import facter from '../apis/facter';
import {
    SIGN_IN,
    SIGN_OUT,
    SET_USER_PREFERENCE
} from './types';
import { defaultCountry, defaultLanguage } from '../locales'

// i18n operations
export const changeLanguage = (lang, dispatch) => {
    localStorage.setItem('i18n', lang)
    
    if (dispatch) {
        dispatch(setLocale(lang))
    }
}

// search bar
export const changeSearchTerm = (term) => {
    return { type: 'CHANGE_TERM', payload: term };
};

// articles
export const resetArticleList = () => {
    return { type: 'RESET_ARTICLES' };
};

export const seleteArticle = (articleId) => {
    return { type: 'SELECT_ARTICLE', payload: articleId };
};

export const setArticleFilters = (value) => {
    return { type: 'SET_ARTICLE_FILTERS', payload: value };
};

export const setArticleTopics = (value) => {
    return { type: 'SET_ARTICLE_TOPICS', payload: value };
};

export const setArticleSort = (value) => {
    return { type: 'SET_ARTICLE_SORT', payload: value }
}

export const setArticlePagination = (value) => {
    return { type: 'SET_ARTICLE_PAGINATION', payload: value }
}

const articleFilterParams = (getState) => {
    const { repliedByMe, filter } = getState().article
    // TODO: uid is not get correctly
    const uid = getState().isSignedIn ? getState().auth.currentUser.id : null
    // TODO: repliedByMe should based on the local uri...
    // const url = repliedByMe ? '/articles/replied' : '/articles'
    const params = {
        userId: uid,
        replied_by_me: repliedByMe,
        ...filter,
    }
    return { url: '/articles', params }
}

// TODO: fetch with filters
export const fetchMoreArticles = () => async (dispatch, getState) => {
    const { url, params } = articleFilterParams(getState)
    params.pagination.page += 1
    const response = await facter.post(url, params)
    const articles = response.data?.list || []
    const articleIds = articles.map((article) => article.id)
    const replies = await Promise.all(articleIds.map(async (articleId) => {
        const replyResp = await _fetchReplies(articleId)
        return replyResp?.data
    }))
    await dispatch({ type: 'MAP_ARTICLE_IN_REPLY', payload: {
        articleIds,
        replies,
    }})
    await dispatch({ type: 'FETCH_MORE_ARTICLES', payload: response.data })
}

// TODO: fetch with filters
export const fetchArticles = () => async (dispatch, getState) => {
    const { url, params } = articleFilterParams(getState)
    const response = await facter.post(url, params);

    const articles = response.data?.list || []
    const articleIds = articles.map((article) => article.id)
    const replies = await Promise.all(articleIds.map(async (articleId) => {
        const replyResp = await _fetchReplies(articleId)
        return replyResp?.data
    }))
    await dispatch({ type: 'MAP_ARTICLE_IN_REPLY', payload: {
        articleIds,
        replies,
    }})
    await dispatch({ type: 'FETCH_ARTICLES', payload: response.data })
};

export const searchAritcles = (term) => async (dispatch, getState) => {
    const response = await facter.get(`/articles/search/${term}`)
    const articles = response.data || []
    const articleIds = articles.map((article) => article.id)
    const replies = await Promise.all(articleIds.map(async (articleId) => {
        const replyResp = await _fetchReplies(articleId)
        return replyResp?.data
    }))

    await dispatch({ type: 'MAP_ARTICLE_IN_REPLY', payload: {
        articleIds,
        replies,
    }})
    await dispatch({ type: 'SEARCH_ARTICLES', payload: response.data });
};

export const nextArticlePage = () => {
    return { type: 'NEXT_ARTICLE_PAGE' };
};

export const beforeFetchArticles = () => {
    return { type: 'LOAD_ARTICLES' };
};

export const setRepliedByMeArticles = (replied) => {
    return { type: 'SET_REPLIED_ARTICLES', payload: replied }
}

export const resetArticleFilter = () => {
    return { type: 'RESET_ARTICLE_FILTER' }
}

// replies
export const resetReplies = () => {
    return { type: 'RESET_REPLIES' };
};

export const fetchReplies = (articleId) => async (dispatch, getState) => {
    const currMapArticle = getState()?.reply?.mapArticle || {}
    const replies = currMapArticle[articleId] || []

    if (replies.length === 0) {
        const response = await _fetchReplies(articleId, dispatch, getState);
        dispatch({ type: 'FETCH_REPLIES', payload: response.data })
        return
    }
    
    dispatch({ type: 'FETCH_REPLIES_FROM_MAP_ARTICLE', payload: { articleId, } })
}

const _fetchReplies = _.memoize(async (articleId, dispatch, getState) => {
    return  await facter.get(`/articles/${articleId}/replies`);
})

export const beforeLoadReplies = () => {
    return { type: 'LOAD_RELIES' };
};

export const postReply = (articleId, formValues) => async (dispatch, getState) => {
    const currentUserId = getState().auth.currentUser.id;
    const params = { ...formValues, language: 'zh', provider_id: currentUserId };
    const response = await facter.post(`/articles/${articleId}/replies`, params);

    dispatch({ type: 'POST_REPLY', payload: response.data });
};

// form operation
export const afterSave = () => {
    return { type: 'SAVE_SUCCESS' };
};

// login / logout / auth

export const fetchUser = (usersEmail) => async (dispatch, getState) => {
    const response = await _fetchUser(usersEmail, dispatch);
    dispatch({ type: 'FETCH_USER', payload: response.data });
}

const _fetchUser = _.memoize(async (usersEmail, dispatch) => {
    return await facter.get(`/users/${usersEmail}`);
});

const isNewUser = currentUser => {
    return _.isEmpty(currentUser?.preference);
};

const signInWithNewUser = (currentUser, dispatch) => {
    const newUser = {
        ...currentUser,
        preference: {
            language: defaultLanguage,
            country: defaultCountry,
        },
    }

    dispatch({ type: SIGN_IN, payload: newUser })
    changeLanguage(defaultLanguage, dispatch)
};

export const signIn = (profilePropties) => async (dispatch, getState) => {
    try {
        const params = {
            display_name: profilePropties.getName(),
            email: profilePropties.getEmail(),
            preference: {
                language: defaultLanguage,
                country: defaultCountry,
            }
        }
        const response = await facter.post('/users/login', params)

        // When user is new, set default preference eg. language
        if (isNewUser(response.data)) return signInWithNewUser(response.data, dispatch)

        dispatch({ type: SIGN_IN, payload: response.data })
        changeLanguage(response.data.preference?.language || defaultLanguage, dispatch)
    } catch (err) {
        console.log(err);
    }
};

export const signOut = () => (dispatch) => {
    dispatch({ type: SIGN_OUT })
    dispatch(setLocale(defaultLanguage))
};

//statistics

export const fetchTopArticlesWeekly = (language) => async (dispatch) => {
    const daysAgo = 7
    const response = await facter.get(`/statistics/articles/topn/${language}/${daysAgo}?n=10`);

    dispatch({ type: 'FETCH_TOP_10_ARTICLES_WEEKLY', payload: response.data });
};

export const fetchTopRepliesWeekly = (language) => async (dispatch) => {
    const daysAgo = 7;
    const response = await facter.get(`/statistics/replies/topn/${language}/${daysAgo}?n=10`);

    dispatch({ type: 'FETCH_TOP_10_REPLIES_WEEKLY', payload: response.data });
};

export const updateUserLanguage = (language) => async (dispatch, getState) => {
    const { currentUser } = getState().auth
    const params = {
        email: currentUser.email,
        display_name: currentUser.display_name,
        preference: {
            language,
            country: currentUser.preference.country,
        }
    }
    const { data } = await facter.put(`/users/${currentUser.id}`, params);
    dispatch({ type: SET_USER_PREFERENCE, payload: data.preference })
    changeLanguage(language, dispatch)
}

export const fetchReplyType = () => async (dispatch, getState) => {
    const { locale } = getState().i18n
    const { data } = await facter.get(`/reply_types/${locale}`)
    dispatch({ type: 'SET_REPLY_TYPE_ENUM', payload: data });
}

export const updateArticleTopics = async (articleId, topics) => {
    return await facter.put(`articles/${articleId}/topics`, { topics })
}

export const fetchRelatedArticleList = (articleId) => async (dispatch) => {
    try {
        const data = await facter.get(`articles/${articleId}/related`)
        dispatch({ type: 'FETCH_RELATED_ARTICLE_LIST', payload: data?.data?.relatedArticles || [] })
    } catch (error) {
        console.error(error)
    }
}
