import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import { fetchRelatedArticleList } from '../../actions'
import Article from './Article'

const RelatedArticleList = ({ articleId }) => {
  const relatedArticles = useSelector(state => state.article.related_list)
  const selectedArticleId = useSelector(state => state.article.selectedArticleId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRelatedArticleList(articleId))
  }, [selectedArticleId, articleId, dispatch])

  if (relatedArticles.length === 0) {
    return <div>{I18n.t('no_related_articles')}</div>
  }

  return relatedArticles.map((article) => (
      <Article key={article.id} article={article}/>
   ))
}

export default RelatedArticleList
