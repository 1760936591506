import { combineReducers } from 'redux';
import { reducer as formReducer, actionTypes as formActionTypes } from 'redux-form';
import { i18nReducer } from "react-redux-i18n";
import articlesReducer from './articlesReducer';
import repliesReducer from './repliesReducer';
import authReducer from './authReducer';
import searchReducer from './searchReducer';
import statisticsReducer from './statisticsReducer';
import enums from './enums/index'

const removeUnregisteredFormFieldValue = (state, action) => {
    const {
        values: { [action.payload.name]: unregisteredValue, ...values },
        fields: { [action.payload.name]: unregisteredField, ...fields }
    } = state;

    return { ...state, values, fields };
};

const formHackReducer = (state, action) => {
    if (!action.meta || !action.meta.form) return formReducer(state, action)

    return (
        formReducer.plugin({
            [action.meta.form]: (state, action) => {
                switch (action.type) {
                    case 'SAVE_SUCCESS':
                        return undefined;
                    case formActionTypes.UNREGISTER_FIELD:
                        return removeUnregisteredFormFieldValue(state, action)
                    default:
                        return state;
                }
            }
        })(state, action)
    )
 }

export default combineReducers({
    form: formHackReducer,
    auth: authReducer,
    article: articlesReducer,
    reply: repliesReducer,
    searchTerm: searchReducer,
    statistics: statisticsReducer,
    i18n: i18nReducer,
    enums,
});
