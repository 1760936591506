import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import ReplyItem from '../ReplyItem'
import { postReply } from '../../actions'

const RepliedList = ({ articleId, replies }) => {
  const dispatch = useDispatch()
  const handleClick = useCallback((reply) => {
    const params = {
      type: reply.type,
      content: reply.content,
      source: reply.source
    }

    dispatch(postReply(articleId, params))
  }, [articleId, dispatch])

  return (
    <div>
      {
        replies.map((reply) => (
          <div key={reply.id} className="ui vertical segment">
            <ReplyItem reply={reply} key={reply.id} />
            <button className="fluid ui button" onClick={() => handleClick(reply)}>{I18n.t('use_this_reply')}</button>
          </div>
        ))
      }
    </div>
  )
}

export default RepliedList
