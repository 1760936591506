const zhTW = {
  article_list: '文章列表',
  statistics: '統計',
  article_search: '文章搜尋',
  more_articles: '更多文章',
  sign_out: '登出',
  sign_in_with_google: 'Google 登入',
  back_to_article_list: '回文章列表',
  loading: '載入中',
  replies: '回應次數',
  queries: '搜尋次數',
  submit: '送出',
  reply_content: '內容',
  reply_content_placeholder: {
    undefined: '請輸入查證內容',
    RUMOR: '請簡單敘述含有錯誤訊息的理由，聊天機器人使用者在得到回應時會馬上了解為什麼錯誤的導讀',
    NOT_RUMOR: '請簡單敘述含有正確訊息的理由，聊天機器人使用者在得到回應時會馬上了解為什麼正確的導讀',
    OPINIONATED: '請提醒讀者這個訊息哪個部分是個人觀點並非客觀事實',
    NOT_ARTICLE: '請說明不需要處理這則訊息的理由'
  },
  reply_source_label: 'Source',
  reply_source: {
    undefined: '佐證資料',
    RUMOR: '佐證資料',
    NOT_RUMOR: '佐證資料',
    OPINIONATED: '反對意見與資料出處'
  },
  reply_templete_button_text: {
    content_too_short: '長度太短',
    commercial: '商業促銷',
    no_valid_required: '無查證需要',
    chat: '聊天',
    test: '無意義測試',
    invalid_link: '連結失效',
  },
  reply_templete_content: {
    content_too_short: '文字長度太短，疑似為使用者手動輸入之查詢語句，不像轉傳文章。',
    commercial: '這是商業活動廣告，活動期間到⋯⋯',
    no_valid_required: '訊息與謠言查證無關。',
    chat: '送出文章的人在嘗試與機器人聊天。',
    test: '測試用之無意義訊息。',
    invalid_link: '本連結已失效，經搜尋引擎查詢相關線索也無法找到任何匹配結果，無法有效查證。',
  },
  reply_source_placeholder: '來源說明與連結網址，若有多個來源請使用 enter 斷行方便使用者閱讀',
  empty_reply_content: '請輸入查核查證內容',
  empty_reply_type: '請選擇查核類型',
  select_reply_type: '請選擇查核類型',
  NOT_ARTICLE: '不在查證範圍',
  OPINIONATED: '含有個人意見',
  NOT_RUMOR: '含有正確訊息',
  RUMOR: '含有錯誤訊息',
  locale: {
    zhTW: '繁體中文',
    en: 'English',
    ja: '日本語',
    th: 'ไทย',
    pt: 'Portugal',
  },
  articleFilter: {
    not_replied: '尚未回覆',
    replied: '已回覆',
    popular_inquiry: '熱門詢問',
    politics: '政治',
    health_information: '健康',
    chicken_soup_for_the_soul: '心靈雞湯',
    scam: '詐騙',
    topics: '文章分類',
    filters: '篩選',
  },
  replied_article_list: '已回覆的文章',
  sorted_by: '排序方式',
  newest: '最新',
  most_asked: '最多詢問',
  article_topics: {
    politics: '政策',
    health_information: '健康',
    chicken_soup_for_the_soul: '心靈雞湯',
    scam: '詐騙',
    add_topics: '添加文章分類',
  },
  use_this_reply: '使用此回覆',
  no_related_articles: '沒有相關文章',
  direct_reply: '直接回覆',
  related_article_replies: '相關文章的回覆',
}

export default zhTW
