import React, { useEffect } from 'react';
import { connect, useDispatch  } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './Header';
import { fetchReplyType } from '../actions'
import routes from '../routes'

const App = ({ i18n }) => {
    const dispatch = useDispatch()
    const { locale } = i18n

    useEffect(() => {
        dispatch(fetchReplyType())
    }, [dispatch, locale])


    return (
        <div className="ui container">
            <BrowserRouter>
                <div>
                    <Header />
                    <Switch>
                        {
                            routes.map((route, index) => {
                                if (!route.component) return null
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={(props) => <route.component {...props} />}
                                    />
                            )
                        })}
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
    );
}

const mapStateToProps = (state) => {
    return { i18n: state.i18n };
}

export default connect(mapStateToProps)(App);
