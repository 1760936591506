import React from 'react';
import { NavLink } from 'react-router-dom';
import { I18n } from 'react-redux-i18n'
import { useSelector } from 'react-redux'

import SearchBar from './SearchBar';
import GoogleAuth from './GoogleAuth';
import LanguageSwitcher from './LanguageSwitcher'

const Header = () => {
    const isSignedIn = useSelector(state => state.auth.isSignedIn)

    return (
        <div className="ui secondary pointing menu">
            <NavLink className="item" activeClassName="active" exact to="/">
                {I18n.t('article_list')}
            </NavLink>
            {
                isSignedIn &&
                    <NavLink className="item" activeClassName="active" exact to="/replied">
                        {I18n.t('replied_article_list')}
                    </NavLink>
            }
            <NavLink className="item" activeClassName="active" to="/statistics">
                {I18n.t('statistics')}
            </NavLink>
            <div className="right menu">
                <SearchBar />
                <GoogleAuth />
                <LanguageSwitcher />
            </div>
        </div>
    );
}


export default Header;
