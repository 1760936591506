const initialState = {
	list: [],
	mapArticle: {},
	onLoading: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_RELIES':
			return { ...state, onLoading: true }
		case 'FETCH_REPLIES':
			return { ...state, list: action.payload, onLoading: false }
		case 'FETCH_REPLIES_FROM_MAP_ARTICLE':
			const repliesFromArticleMap = state?.mapArticle[action.payload.articleId] || []
			return { ...state, list: repliesFromArticleMap, onLoading: false }
		case 'POST_REPLY':
			const current_replies = [...state.list, action.payload]
			return { ...state, list: current_replies, onLoading: false }
		case 'RESET_REPLIES':
			return { ...state, list: [] }
		case 'MAP_ARTICLE_IN_REPLY':
			const articleIds = action.payload.articleIds
			const replies = action.payload.replies
			const newMapArticleState = state.mapArticle
			for (const index of articleIds.keys()) {
				newMapArticleState[articleIds[index]] = replies[index]
			}
			return { ...state, mapArticle: newMapArticleState }
		default:
			return state
	}
}
