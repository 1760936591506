import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import {
    loadTranslations,
    setLocale,
    syncTranslationWithStore,
} from 'react-redux-i18n'

import reducers from './reducers';
import locales, { defaultLanguage } from './locales';

const middlewares = [thunk]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middlewares))
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(locales));
store.dispatch(setLocale(defaultLanguage))

export default store
