import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import classnames from 'classnames'

import {
  setArticleFilters,
  setArticleTopics,
  resetArticleList,
  fetchArticles,
} from '../../actions'

import { topicsOption, filtersOption } from '../../reducers/articlesReducer'

const ArticleFilter = () => {
  const dispatch = useDispatch()
  const { filters, topics } = useSelector((state) => state.article.filter)

  const handleFilters = useCallback(async (selected) => {
    let newFilters = Array.from(filters)
    if (filters.includes(selected)) {
      newFilters = newFilters.filter(ele => ele !== selected)
    } else {
      newFilters.push(selected)
      switch (selected) {
        case 'not_replied':
          newFilters = newFilters.filter(ele => ele !== 'replied')
          break
        case 'replied':
          newFilters = newFilters.filter(ele => ele !== 'not_replied')
          break
        default:
          break
      }
    }
    await dispatch(setArticleFilters(newFilters))
    await dispatch(resetArticleList())
    dispatch(fetchArticles())
  }, [dispatch, filters])

  const handleTopics = useCallback(async (selected) => {
    let newTpoics = Array.from(topics)
    if (topics.includes(selected)) {
      newTpoics = newTpoics.filter(ele => ele !== selected)
    } else {
      newTpoics.push(selected)
    }
    await dispatch(setArticleTopics(newTpoics))
    await dispatch(resetArticleList())
    dispatch(fetchArticles())
  }, [dispatch, topics])

  const btnClass = useCallback((selected) => (
    classnames('ui', 'button', { secondary: selected })
  ), [])

  return (
    <div className="ui four column grid ui segment" style={{ border: '1px solid #d6d6d6' }}>
      <div className="row">
        <div className="column">{I18n.t('articleFilter.filters')}</div>
        <div className="twelve wide column">
          {
            filtersOption.map((filter) => (
              <button
                key={filter}
                className={btnClass(filters.includes(filter))}
                onClick={() => handleFilters(filter)}
              >
                {I18n.t(`articleFilter.${filter}`)}
              </button>
            ))
          }
        </div>
      </div>
      <div className="ui divider" />
      <div className="row">
        <div className="column">{I18n.t('articleFilter.topics')}</div>
        <div className="twelve wide column">
          {
            topicsOption.map((topic) => (
              <button
                key={topic}
                className={btnClass(topics.includes(topic))}
                onClick={() => handleTopics(topic)}
              >
                {I18n.t(`articleFilter.${topic}`)}
              </button>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ArticleFilter
