import React from 'react';
import { searchAritcles, changeSearchTerm } from '../actions';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n'
class SearchBar extends React.Component {
    onInputChange = (event) => {
        this.props.changeSearchTerm(event.target.value);
    };

    onFormSubmit = (event) => {
        event.preventDefault();

        this.props.searchAritcles(this.props.term);
    };

    render() {
        return (
            <div className="item">
                <form onSubmit={this.onFormSubmit} className="ui transparent icon input">
                    <input
                        placeholder={I18n.t('article_search')}
                        type="text"
                        value={this.props.term}
                        onChange={this.onInputChange}
                    />
                    <i className="search link icon"></i>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { articles: state.articles, term: state.searchTerm, i18n: state.i18n };
}

export default connect(mapStateToProps, { searchAritcles, changeSearchTerm })(SearchBar);
