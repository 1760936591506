import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { fetchReplies } from '../../actions';
import ArticleReplyCreate from './ArticleReplyCreate';
import ReplyItem from '../ReplyItem';
import RelatedArticleList from "../RelatedArticles/List"
import { I18n } from 'react-redux-i18n';
class ArticleReplyList extends React.Component {
    state = {
        activeTab: 0,
    }

    renderList() {
        const replies = this.props.replies

        if (this.props.repliesIsOnLoading) {
            return (
                <div className="ui segment">
                    <div className="ui active loader"></div>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
            );
        }

        return replies.map((reply) => {
            return <ReplyItem reply={reply} key={reply.id} />
        });
    }

    renderArticleReplyForm() {
        if (!this.props.isSignedIn) {
            return null
        } else {
            return <ArticleReplyCreate articleId={this.props.articleId}/>;
        }
    }

    tabSelect = (tab) => {
        this.setState({ activeTab: tab })
    }

    render() {
        const { activeTab } = this.state
        return (
            <div className="ui relaxed divided list">
                <div className="ui top attached tabular menu">
                    <div className={classNames('item', { active: activeTab === 0 })} onClick={() => this.tabSelect(0)}>{I18n.t('direct_reply')}</div>
                    <div className={classNames('item', { active: activeTab === 1 })} onClick={() => this.tabSelect(1)}>{I18n.t('related_article_replies')}</div>
                </div>
                <div className={classNames('ui bottom attached tab segment', { active: activeTab === 0 })}>
                    {this.renderArticleReplyForm()}
                </div>
                <div className={classNames('ui bottom attached tab segment', { active: activeTab === 1 })}>
                    <RelatedArticleList articleId={this.props.articleId} />
                </div>
                {this.renderList()}
            </div>
        );
    }
}

const mapStateToProps = (state, _ownProps) => {
    return {
        repliesIsOnLoading: state.reply.onLoading,
        isSignedIn: state.auth.isSignedIn,
    }
}

export default connect(mapStateToProps, { fetchReplies })(ArticleReplyList)
