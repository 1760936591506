import React from 'react'
import Moment from 'react-moment';
import { useSelector } from 'react-redux';

import { momentLocaleMapping } from '../../locales'

import RepliedList from './RepliedList'

const Article = ({ article }) => {
  const { locale } = useSelector(state => state.i18n)
  return (
    <div className="ui stacked segment">
      <div className="row">
        <div className="column" >
          <div style={{ textAlign: "right" }}>
            <Moment locale={momentLocaleMapping[locale]} fromNow>{article.created_at}</Moment>
          </div>
          <div className="content" >
            <div className="description">
              <p>{article.content}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <RepliedList articleId={article.id} replies={article.replies}/>
        </div>
      </div>
    </div>
  )
}

export default Article
