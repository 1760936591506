import React from 'react';
import StatisticsArticles from './StatisticsArticles';
import StatisticsReplies from './StatisticsReplies';
import { connect } from 'react-redux';

class StatisticsPage extends React.Component {
    renderReplies() {
        if (this.props.isSignedIn) {
            return <StatisticsReplies />;
        }
    }

    render() {
        return (
            <div>
            <StatisticsArticles />
            {this.renderReplies()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { isSignedIn: state.auth.isSignedIn };
}

export default connect(mapStateToProps, {})(StatisticsPage);
