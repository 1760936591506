import axios from 'axios'
import { acceptLanguage } from '../locales'

const baseURL =
	process.env.REACT_APP_ENV === 'production'
		? 'https://api.checkcheck.me'
		: 'https://api-staging.checkcheck.me'

axios.interceptors.request.use(function (config) {
    config.baseURL = baseURL
    config.headers['Accept-Language'] = acceptLanguage[localStorage.getItem('i18n') || 'zhTW']

    return config
})

export default axios
