import React , { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setRepliedByMeArticles, resetArticleFilter, fetchArticles } from '../../actions'

import ArticleFilter from './ArticleFilter'
import ArticleList from './ArticleList'
import Sorter from './Sorter'

const ArticlesPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setRepliedByMeArticles(false))
    dispatch(resetArticleFilter())
    dispatch(fetchArticles())
  }, [dispatch])

  return (
    <>
      <ArticleFilter />
      <Sorter />
      <ArticleList />
    </>
  )
}

export default ArticlesPage
