import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n'
import classnames from 'classnames'

import { postReply, afterSave } from '../../actions';

const templetes = ['content_too_short', 'commercial', 'no_valid_required', 'chat', 'test', 'invalid_link']

const TempleteButton = ({ onChange, templete }) => {
    const buttonText = I18n.t(`reply_templete_button_text.${templete}`)
    const content = I18n.t(`reply_templete_content.${templete}`)

    return (
        <button className="mini ui button" type="button" onClick={() => onChange(content)}>
            {buttonText}
        </button>
    )
}
class ArticleReplyCreate extends React.Component {
    renderInput({ input, meta, label, placeholder }) {
        return (
            <div className={`field ${classnames({ error: meta.error && meta.touched })}`}>
                <label>{label}</label>
                <input {...input} placeholder={placeholder}/>
                {
                    meta.touched && meta.error &&
                        <div class="ui pointing red basic label">
                            {meta.error}
                        </div>
                }
            </div>
        );
    }

    renderTextArea({ input, meta, label, placeholder, textareaProps }) {
        return (
            <div className={`field ${classnames({ error: meta.error && meta.touched })}`}>
                <label>{label}</label>
                <textarea {...input} placeholder={placeholder} {...textareaProps}/>
                {
                    meta.touched && meta.error &&
                        <div class="ui pointing red basic label">
                            {meta.error}
                        </div>
                }
            </div>
        );
    }

    renderTempleteTextArea({ input, meta, label, placeholder, textareaProps }) {
        return (
            <div className={`field ${classnames({ error: meta.error && meta.touched })}`}>
                <label>{label}</label>
                <div className="ui secondary segment" style={{ border: 0 }}>
                    {
                        templetes.map((templete) => (
                            <TempleteButton key={templete} templete={templete} onChange={input.onChange} />
                        ))
                    }
                </div>
                <textarea {...input} placeholder={placeholder} {...textareaProps}/>
                {
                    meta.touched && meta.error &&
                        <div class="ui pointing red basic label">
                            {meta.error}
                        </div>
                }
            </div>
        );
    }

    renderArticleFields(selectedType) {
        return (
            <>
                <Field
                    name="content"
                    component={this.renderTextArea}
                    label={I18n.t('reply_content')}
                    placeholder={I18n.t(`reply_content_placeholder.${selectedType}`)}
                />
                <Field
                    name="source"
                    component={this.renderTextArea}
                    label={I18n.t(`reply_source.${selectedType}`)}
                    placeholder={I18n.t('reply_source_placeholder')}
                    textareaProps={{ rows: 2 }}
                />
            </>
        )
    }

    renderNotArticleFields(selectedType) {
        return (
            <Field
                name="content"
                component={this.renderTempleteTextArea}
                label={I18n.t('reply_content')}
                placeholder={I18n.t(`reply_content_placeholder.${selectedType}`)}
            />
        )
    }

    renderReplyTypeField({ input, meta }) {
        return (
            <div className={`field ${classnames({ error: meta.error && meta.touched })}`}>
                <select {...input}>
                    <option className="item" value="">{I18n.t('select_reply_type')}</option>
                    <option className="item" value="RUMOR">{I18n.t('RUMOR')}</option>
                    <option className="item" value="NOT_RUMOR">{I18n.t('NOT_RUMOR')}</option>
                    <option className="item" value="OPINIONATED">{I18n.t('OPINIONATED')}</option>
                    <option className="item" value="NOT_ARTICLE">{I18n.t('NOT_ARTICLE')}</option>
                </select>
                {
                    meta.touched && meta.error &&
                        <div class="ui pointing red basic label">
                            {meta.error}
                        </div>
                }
            </div>
        )
    }

    render() {
        const { selectedType } = this.props
        return (
            <div className="ui segment">
                <form onSubmit={this.props.handleSubmit} className='ui form'>
                    <Field
                        name="type"
                        component={this.renderReplyTypeField}
                    />
                    {
                        selectedType !== 'NOT_ARTICLE' ?
                            this.renderArticleFields(selectedType) :
                            this.renderNotArticleFields(selectedType)
                    }
                    <button className="ui primary button" onClick={this.props.handleSubmit}>{I18n.t('submit')}</button>
                </form>
            </div>
        );
    }
}

const validate = (formValues) => {
    const errors = {};

    if (!formValues.content) {
        errors.content = I18n.t('empty_reply_content');
    }

    if (!formValues.type) {
        errors.type = I18n.t('empty_reply_type');
    }

    return errors;
};

const formWrapped = reduxForm({
    form: 'ArticleReplyCreation',
    validate
})( ArticleReplyCreate );

const selector = formValueSelector('ArticleReplyCreation')
const mapStateToProps = (state) => {
    const selectedType = selector(state, 'type')

    return { i18n: state.i18n, selectedType };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: (formValues) => {
            dispatch(postReply(ownProps.articleId, formValues))
            dispatch(afterSave())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(formWrapped);
