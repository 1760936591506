import React from 'react';
import ReplyItem from './ReplyItem';
import { connect } from 'react-redux';
import { fetchTopRepliesWeekly } from '../actions';

class StatisticsReplies extends React.Component {
    componentDidMount() {
        this.props.fetchTopRepliesWeekly('zh');
    }

    renderWeeklyList() {
        if (this.props.top_10_replies_weekly.length > 0) {
            return this.props.top_10_replies_weekly.map((reply) => <ReplyItem reply={reply} key={reply.id} />);
        } else {
            return <div>抱歉，目前還沒有統計資料喔。</div>
        }
    }

    render() {
        return (
            <div className="ui segment">
                <h2 className="ui left floated header">Weekly Top 10 Replies by you</h2>
                <div className="ui clearing divider"></div>
                {this.renderWeeklyList()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { top_10_replies_weekly: state.statistics.top_10_replies_weekly };
};

export default connect(mapStateToProps, { fetchTopRepliesWeekly })(StatisticsReplies);;
