import { SIGN_IN, SIGN_OUT } from '../actions/types';
import userPreferenceReducer from './userPreferenceReducer';

const INITIAL_STATE = {
    isSignedIn: null,
    currentUser: {
        preference: null
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGN_IN:
            return { ...state, isSignedIn: true, currentUser: action.payload };
        case SIGN_OUT:
            return { ...state, isSignedIn: false };
        default:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    preference: userPreferenceReducer(state.currentUser.preference, action)
                }
            }
    }
};
