const en = {
  article_list: 'Article List',
  statistics: 'Statistics',
  article_search: 'Article Search',
  more_articles: 'More Articles',
  sign_out: 'Sign Out',
  sign_in_with_google: 'Sign In With Google',
  back_to_article_list: 'Back to Article List',
  loading: 'Loading',
  replies: 'Replies',
  queries: 'Queries',
  submit: 'Submit',
  reply_source_label: 'Source',
  reply_content: 'Content',
  reply_content_placeholder: {
    undefined: 'Please enter the verification content',
    RUMOR: 'Please briefly state the reason for the error message, the chatbot will immediately understand why the wrong guide was given when receiving a response',
    NOT_RUMOR: 'Please briefly state the reason for the correct message, the chatbot will immediately understand why the correct guide is given when they get a response',
    OPINIONATED: 'Please remind the reader which part of this message is a personal opinion and not an objective fact',
    NOT_ARTICLE: 'Please explain why you do not need to process this message'
  },
  reply_source: {
    undefined: 'Supporting information',
    RUMOR: 'Supporting Information',
    NOT_RUMOR: 'Supporting information',
    OPINIONATED: 'Objection and source of information'
  },
  reply_templete_button_text: {
    content_too_short: 'Length is too short',
    commercial: 'Commercial Promotion',
    no_valid_required: 'No Verification Required',
    chat: 'Chat',
    test: 'Testing',
    invalid_link: 'Invalid Link',
  },
  reply_templete_content: {
    content_too_short: 'The length of the text is too short, and it is suspected to be a query sentence entered manually by the user, not like a reposted article.',
    commercial: 'This is a commercial event advertisement. During the event period...',
    no_valid_required: 'The message has nothing to do with the verification of the rumor.',
    chat: 'The person who sent the article is trying to chat with the bot.',
    test: 'Testing',
    invalid_link: 'This link is no longer valid, and no matching results can be found after searching for relevant clues by search engines, which cannot be effectively verified',
  },
  reply_source_placeholder: 'Source description and destination URL, if there are multiple sources, please use enter to break the line for users to read',
  empty_reply_content: 'You must give a reason for reply an article',
  empty_reply_type: 'You must select a type for your reply',
  select_reply_type: 'Select Reply Type',
  NOT_ARTICLE: 'NOT ARTICLE',
  OPINIONATED: 'OPINIONATED',
  NOT_RUMOR: 'NOT RUMOR',
  RUMOR: 'RUMOR',
  locale: {
    zhTW: '繁體中文',
    en: 'English',
    ja: '日本語',
    th: 'ไทย',
    pt: 'Portugal',
  },
  articleFilter: {
    not_replied: 'Not Replied',
    replied: 'Replied',
    popular_inquiry: 'Popular Inquiry',
    politics: 'Politics',
    health_information: 'Health Information',
    chicken_soup_for_the_soul: 'Checken Soup For The Soul',
    scam: 'Scam',
    topics: 'Topics',
    filters: 'Filters',
  },
  replied_article_list: 'Replied Article List',
  sorted_by: 'Sorted By',
  newest: 'Newest',
  most_asked: 'Most Asked',
  article_topics: {
    politics: 'Politics',
    health_information: 'Health Information',
    chicken_soup_for_the_soul: 'Chicken Soup For The Soul',
    scam: 'Scam',
    add_topics: 'Add Topic',
  },
}

export default en
