import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { setRepliedByMeArticles, resetArticleFilter, fetchArticles } from '../../actions'

import ArticleFilter from './ArticleFilter'
import ArticleList from './ArticleList'
import Sorter from './Sorter'

const RepliedArticlePage = () => {
  const dispatch = useDispatch()

  const isSignedIn = useSelector(state => state.auth.isSignedIn)

  useEffect(() => {
    dispatch(setRepliedByMeArticles(true))
    dispatch(resetArticleFilter())
    dispatch(fetchArticles())
  }, [dispatch])

  if (!isSignedIn) {
    return <Redirect to='/'/>
  }

  return (
    <>
      <ArticleFilter />
      <Sorter />
      <ArticleList />
    </>
  )
}

export default RepliedArticlePage
