export const filterInitialState = {
    filters: [],
    topics: [],
    pagination: { page: 0 },
    sort: { order: "desc", field: "created_at" }
}

export const topicsOption = [
    'politics',
    'health_information',
    'chicken_soup_for_the_soul',
    'scam'
]

export const filtersOption = [
    'not_replied',
    'replied',
    'popular_inquiry'
]

const initialState = {
    repliedByMe: false,
    list: [],
    selectedArticleId: null,
    currentPage: 0,
    onLoading: false,
    isLastPage: false,
    filter: filterInitialState,
    related_list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ARTICLES':
            return { ...state, list: action.payload.list, onLoading: false, currentPage: action.payload.pagination.page, isLastPage: action.payload.isLastPage };
        case 'FETCH_MORE_ARTICLES':
            const current_articles = state.list.concat(action.payload.list)
            return { ...state, list: current_articles, onLoading: false, currentPage: action.payload.pagination.page, isLastPage: action.payload.isLastPage };
        case 'SEARCH_ARTICLES':
            return { ...state, list: action.payload, onLoading: false, isLastPage: true, currentPage: 0 };
        case 'SELECT_ARTICLE':
            return { ...state, selectedArticleId: action.payload, onLoading: false };
        case 'LOAD_ARTICLES':
            return { ...state, onLoading: true };
        case 'RESET_ARTICLES':
            return { ...state, list: [] };
        case 'NEXT_ARTICLE_PAGE':
            const next_page = state.currentPage + 1;
            return { ...state, currentPage: next_page };
        case 'RESET_ARTICLE_FILTER':
            return { ...state, filter: filterInitialState }
        case 'SET_ARTICLE_FILTERS':
            return { ...state, filter: { ...state.filter, filters: action.payload } };
        case 'SET_ARTICLE_TOPICS':
            return { ...state, filter: { ...state.filter, topics: action.payload } };
        case 'SET_REPLIED_ARTICLES':
            return { ...state, replied: action.payload }
        case 'SET_ARTICLE_SORT':
            return { ...state, filter: { ...state.filter, sort: action.payload } };
        case 'SET_ARTICLE_PAGINATION':
            return { ...state, filter: { ...state.filter, pagination: action.payload } };
        case 'FETCH_RELATED_ARTICLE_LIST':
            return { ...state, related_list: action.payload }
        default:
            return state;
    }
};
