import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { momentLocaleMapping } from '../locales'
import { fetchArticles, updateUserLanguage } from '../actions'

const LanguageSwitcher = () => {
	const isSignedIn = useSelector((state) => state.auth.isSignedIn)
	const currentLocale = useSelector((state) => state.i18n.locale)
	const dispatch = useDispatch()

	const handleChange = useCallback(
		async (event) => {
			await dispatch(updateUserLanguage(event.target.value))
			await dispatch(fetchArticles())
		},
		[dispatch]
	)

	if (!isSignedIn) {
		return <></>
	}

	return (
		<select
			id='language-switcher'
			value={currentLocale}
			onChange={handleChange}
		>
			{Object.keys(momentLocaleMapping).map((key) => (
				<option disabled={key === 'en'} key={key} value={key}>
					{I18n.t(`locale.${key}`)}
				</option>
			))}
		</select>
	)
}

export default LanguageSwitcher
