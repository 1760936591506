import React from 'react';
import { connect } from 'react-redux';
import { ReactTinyLink  } from 'react-tiny-link'
import { seleteArticle, beforeLoadReplies, fetchReplies, resetReplies } from '../../actions';
import Moment from 'react-moment';
import ArticleReplyList from './ArticleReplyList';
import normalizeUrl from 'normalize-url';
import { I18n } from 'react-redux-i18n'

import { momentLocaleMapping } from '../../locales'
import ArticleTopicsSelector from './ArticleTopicsSelector'

class ArticleItem extends React.Component {
    state = { selectedTopics: this.props.topics || [] }

    divOnClick(event, article) {
        event.preventDefault();
        this.props.seleteArticle(article.id);
        if (article.reply_count > 0) {
            this.props.beforeLoadReplies();
            this.props.fetchReplies(article.id);
        } else {
            this.props.resetReplies();
        }
    }

	abbreviateNumber(value) {
        if (value < 1000) {
            return value
        }
        const suffixes = ["", "k", "m", "b","t"]
		const suffixNum = Math.floor(`${value}`.length/3 )
        let shortValue = 0
        for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision))
            const dotLessShortValue = `${shortValue}`.replace(/[^a-zA-Z 0-9]+/g,'')
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1)
        return shortValue+suffixes[suffixNum]
	}

    renderArticleReplyList(articleId) {
        if (this.props.selectedArticleId === articleId) {
            return <ArticleReplyList articleId={articleId} replies={this.props.replies} />;
        }
    }

    renderItem() {
        const article = this.props.article
        const reply = (this.props?.mapArticle[article.id] || [])[0]

        return (
            <div className="item">
                <div className="ui grid">
                    <ArticleTopicsSelector articleId={article.id} selectedTopics={article.topics} isSignedIn={this.props.isSignedIn} />
                    {
                        reply?.source && 
                        <div className="row" style={{
                            paddingLeft: '14px',
                            paddingRight: '14px',
                        }}>
                            <a href={reply.source} target='_blank' rel='noopener noreferrer'>{reply.source}</a>
                        </div>
                    }
                    <div className="row" onClick={(event) => this.divOnClick(event, article)}>
                        <div className="four wide column">
                            <div className="ui statistic">
                                <div className="label">{I18n.t('replies')}</div>
                                <div className="value">{article.reply_count}</div>
                            </div>
                            <div className="ui statistic">
                                <div className="label">{I18n.t('queries')}</div>
                                <div className="value">
                                    {this.abbreviateNumber(article.query_count)}
                                </div>
                            </div>
                        </div>
                        <div className="twelve wide column" >
                            <div style={{ textAlign: "right" }}>{this.renderCreatedAt(article.created_at)}</div>
                            <div className="content" >
                                <div className="description">
                                    <p>{article.content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="four wide column"></div>
                        <div className="twelve wide column">{this.renderHyperlinks(article.hyperlinks)}</div>
                    </div>
                    <div className="row">
                        <div className="sixteen wide column">{this.renderArticleReplyList(article.id)}</div>
                    </div>
                </div>
            </div>
        )
    }

    renderCreatedAt(created_at) {
        const { locale } = this.props.i18n
        return (
            <Moment locale={momentLocaleMapping[locale]} fromNow>{created_at}</Moment>
        );
    }

    renderHyperlinks(hyperlinks) {
        return hyperlinks.map((hyperlink, index) => <ReactTinyLink key={index} cardSize="small" showGraphic={true} maxLine={2} minLine={1} url={normalizeUrl(hyperlink)} />);
    }

    render() {
        return this.renderItem();
    }
}

const mapStateToProps = (state) => (
    {
        selectedArticleId: state.article.selectedArticleId,
        replies: state.reply.list,
        mapArticle: state.reply.mapArticle,
        i18n: state.i18n,
        isSignedIn: state.auth.isSignedIn,
    }
)

export default connect(mapStateToProps, { seleteArticle, beforeLoadReplies, fetchReplies, resetReplies })(ArticleItem);
